<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />
    <v-spacer />
    <div class="mx-3" />

    <v-btn class="ml-2" min-width="0" to="/login" v-if="login===false"  color="primary" aria-placeholder="login">
    Login
      <v-icon>mdi-login</v-icon>
    </v-btn>
    <v-btn class="ml-2" min-width="0" text  v-else to="/" @click="logout" color="grey" aria-placeholder="logout">
    Logout
      <v-icon>mdi-logout-variant</v-icon>
    </v-btn>
    <!-- <v-btn class="ml-2" min-width="0" text to="/pages/user">
      <v-icon>mdi-account</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: [
    ],
    login:false
  }),

  computed: {
    ...mapState(["drawer"]),
    ...mapGetters(["authuser/getAuth"])
  },

  watch:{
    '$store.state.authuser.authenticated':async function(val){
      this.login=await val
    }
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    logout(){
      this.$store.dispatch("authuser/logout")
      if(!this.$store.state.authuser.getAuth){
        this.$toast.success("Logout Complete");
      }
    }
  },
};
</script>
